import firebase from './firebase';

var db = firebase.firestore();
// if (window.location.hostname === "127.0.0.1") {
//   db.settings({
//     host: "localhost:8080",
//     ssl: false,
//   })
// }

export function fetchTeams(userId) {
    let teamsPromise = getTeams();
    return {
      teams: wrapPromise(teamsPromise)
    }
  
  // Suspense integrations like Relay implement
  // a contract like this to integrate with React.
  // Real implementations can be significantly more complex.
  // Don't copy-paste this into your project!
  function wrapPromise(promise) {
    let status = "pending";
    let result;
    let suspender = promise.then(
      (r) => {
        status = "success";
        result = r;
      },
      (e) => {
        status = "error";
        result = e;
      }
    );
    return {
      read() {
        if (status === "pending") {
          throw suspender;
        } else if (status === "error") {
          throw result;
        } else if (status === "success") {
          return result;
        }
      }
    };
  }
  
  function getTeams() {
    // console.log("fetch user...");
    let teams = [];
    let teamsReturn = [];
    let teamsObj = {};
    return new Promise((resolve) => {
    db.collection(`${userId}_teams`).get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				// console.log(doc.id)
				teams.push(doc.id);
        if(doc.id !== 'CYPRESS_TESTING') {
          teamsObj[doc.id] = doc.data();
        }
			})
		}).then(() =>{
			// console.log('Hi!');
			// console.log(teams);
      teamsReturn = [teams.filter(team => team !== 'CYPRESS_TESTING'), teamsObj];
			resolve({teams: teamsReturn});            
		})
    });
  }
}
