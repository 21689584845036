import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./css/Dropdown.css";
import NewImporter from './Importer';
import Modal from 'react-modal';
import Button from "./Button/Button";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%'
  },
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundCcolor: 'rgba(0,0,0,0.9)',
    }
};

//eslint-disable-next-line
function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
    return result;
}

export default function Dropdown() {

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
      setIsOpen(false);
  }
  
  const [modalIsOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <Button onClick={openModal} label="Import" bold />
      {/* <button onClick={openModal} className="menu-trigger">
          <span>Import</span>
      </button> */}

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
          >
        <h2>Import Players</h2>
        <br />
        <NewImporter closeModal={closeModal}/>
        <h2>Teams Added: </h2>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          flexDirection: 'column',
          width: '95.5%',
        }}>
          <p id="importing-teams-message" style={{display: 'block'}}>Waiting for import......</p>
          <ul id='teams-add' style={{color: 'black', position: 'absolute', textAlign: 'left', fontSize: '17px'}}></ul>
        </div>
      </Modal> 
    </div>
  );
}