import firebase from "firebase";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../App.css';

/**
 * Consists of the loader in the logout page and logic to redirect back to the LoginScreen once FireBase has confirmed sign-out
 * @returns Logout Page Component
 */

const Logout = () => {
    document.title = `Signing out`
    firebase.auth().signOut().then(() => {
        window.location.replace('/')
    })
    return (
        <div className="App-header"><Loader
            type="TailSpin"
            color="#1e4475"
            height={100}
            width={100}
            timeout={3000} //3 secs
        /></div>
    );
}

export default Logout;