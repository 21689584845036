import 'firebase/firestore';
import React, { Suspense } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Dropdown from './components/Dropdown.new';
import Loader from "react-loader-spinner";
import firebase from './firebase';
import { fetchTeams } from './getTeams';
import './HomeScreen.css';
import logo from './teamlayout-logo.svg';
import Button from './components/Button/Button';
import { withRouter } from 'react-router';
import moment from 'moment';


const db = firebase.firestore();

if (window.location.hostname === "127.0.0.1") {
  db.settings({
    host: "localhost:8081",
    ssl: false,
  })
}


function Teams({userId, club, history, teamsResource}) {
	const teams = teamsResource.teams.read(userId, club);
	// return teams.teams.map(team => 
  //   <div className={"teamBox"} key={team}>
  //     <span><img alt={'teamIcon'} src={HHFCLogoNoGreen} style={{width: 25, height: 'auto', verticalAlign: 'baseline'}}/></span>
  //     <span className={"teamLink"} ><a style={{color: 'forestgreen', textDecoration: 'none'}} href={"/team/edit/"+team}>{team}</a></span>
  //   </div>
	// );
	console.log('Teams')
	console.log(teams.teams)
	if (teams.teams[0].length === 0) {
		return <p>No teams are registered to this acount</p>;
	}
	
	return teams.teams[0].map(team => {
    	return <Button textColor="darkblue" onClick={() => history.push("/team/edit/"+team)} backgroundColor='#FFFFFF' label={`${teams.teams[1][team].isEdited ? '✅': ''}${team}`} key={team} history={history} isEdited={teams.teams[1][team].isEdited}></Button>
	});
};




function HomeScreen(props) {
	const userEmail = firebase.auth().currentUser.email;
	const [billingEndDate, setBillingEndDate] = React.useState(sessionStorage.getItem('billingEndDate'))
	const teamsResource = fetchTeams(firebase.auth().currentUser.uid);

	React.useEffect(() => {
		fetch('/.netlify/functions/get-user', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify({
				email: userEmail
			})            
		  }).then(res => res.json()).then((subscriptions) => {
			console.log(subscriptions);
			if (subscriptions.error) {
			  console.log(subscriptions.error);
			} else {
			  if (subscriptions.validSubscriptions.length > 0) {
				let endDate = moment.unix(subscriptions.endDateEpoch);
				sessionStorage.setItem('billingEndDate', endDate.format("D/M/YYYY"));
				setBillingEndDate(endDate.format("D/M/YYYY"));
			  } else {
				alert('Error: Invalid subscription.\nPlease ensure you have a valid subscription to TeamLayout.');
				firebase.auth().signOut();
				window.location.reload();
			  }
			}
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	// if (props.club === undefined) {
	// 	window.location.replace('/')
	// }
	document.title = `TeamLayout Home`
	// const userId = firebase.auth().currentUser.uid;
	// console.log(firebase.auth().currentUser.uid);

	localStorage.setItem('userId', firebase.auth().currentUser.uid);
	return (
		<div style={{marginTop: '30px'}}>
			{/* <div style={{position: 'absolute', top: '5px', right: '5px', fontSize: '15px', border: '2px solid red', padding: '4px'}}>
				<p>Subscription Renewal: {billingEndDate}</p>
			</div> */}
			<img src={logo} className="App-logo" alt="logo" />
			<h1 style={{color: 'white'}}>TeamLayout</h1>
			<p>Welcome {firebase.auth().currentUser.displayName}!</p>
			<hr />
			{/* eslint-disable-next-line */}
			<div className='taskBar'>
				<Dropdown></Dropdown>
				{/* eslint-disable-next-line*/}
				<div><Button label="Export" onClick={() => props.history.push('/export')} textColor="black" backgroundColor="white" bold></Button></div>
				{/* eslint-disable-next-line*/}
				<Button label="Logout" onClick={() => props.history.push('/logout')} bold></Button>
			</div>
			<hr />
			<div className='Teams-content'>
				<Suspense fallback={<div><Loader id="loader" type="TailSpin" color="#1e4475" height={100} width={100} /></div>}>
					{/* <div className='Teams-content'> */}
						<Teams teamsResource={teamsResource} userId={firebase.auth().currentUser.uid} history={props.history}></Teams>
					{/* </div> */}
				</Suspense>
			</div>

		</div>
	);
}


export default withRouter(HomeScreen);
