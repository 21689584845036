import '@atlaskit/css-reset';
import moment from 'moment';
import React, { Suspense } from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useParams } from "react-router-dom";
import { useAsyncResource } from 'use-async-resource';
import './App.css';
import firebase from './firebase';
import { withRouter } from 'react-router';

const db = firebase.firestore();

if (window.location.hostname === "127.0.0.1") {
	db.settings({
		host: "localhost:8081",
		ssl: false,
	})
}

var dragData = {};

var playerList = {};

var ui = [];
var downloadString = '';
var downloadTitle = '';
var teamsToDownload = [];

var teamData = {};

const teamCheck = (event) => {
	var target = event.target;
	console.log(event.target.name)
	console.log(event.target.checked)
	if (target.checked === false) {
		const index = teamsToDownload.indexOf(target.name);
		if (index > -1) {
			teamsToDownload.splice(index, 1);
		}
	} else {
		teamsToDownload.push(target.name);
	}
	console.log(teamsToDownload)
};

const fetchUser = () => db.collection(`${firebase.auth().currentUser.uid}_teams`).get().then((querySnapshot) => {
	querySnapshot.forEach((doc) => {
		dragData[doc.id] = {};
		dragData[doc.id]['dragData'] = doc.data().dragndropdata;
		dragData[doc.id]['comments'] = doc.data().comments;
	})
}).then(() => {
	ui = [];
	// eslint-disable-next-line
	downloadTitle = 'TeamLayout Players Export - ' + moment().format('YYYY-MM-DD') + '.txt';
	// console.log(dragData);
	for (const team in dragData) {
		if (dragData[team]['dragData']) {
			dragData[team]['dragData']['columnOrder'].forEach((column) => {
				if (playerList[team] === undefined) {
					playerList[team] = {};
				}
				dragData[team]['dragData']['columns'][column]['taskIds'].forEach((playerID) => {
					if (playerID !== undefined) {
						if (playerList[team][dragData[team]['dragData']['columns'][column].id] === undefined) {
							playerList[team][dragData[team]['dragData']['columns'][column].id] = dragData[team]['dragData']['tasks'][playerID].content;
						} else {
							playerList[team][dragData[team]['dragData']['columns'][column].id] = playerList[team][dragData[team]['dragData']['columns'][column].id] + ", " + dragData[team]['dragData']['tasks'][playerID].content;
						}
					}
				})
			})
		}
	}
	for (const team in dragData) {
		// TODO: #39 @homemadestea58
		if (playerList[team] === undefined) {
			ui.push(<><label> - {team}: No Players Registered for this team</label></>)
		} else {
			ui.push(<><input key={team} id={team} name={team} type="checkbox" onChange={teamCheck}></input><label> - {team}: </label></>)
		}
		downloadString += team + '\n';
		for (const column in playerList[team]) {
			var columnName = "";
			// console.log('Team Players')
			// console.log(playerList[team][column])
			if (column === "column-1") {
				columnName = "Absent"
			} else if (column === "column-2") {
				columnName = "Back Row"
			} else if (column === "column-3") {
				columnName = "Middle Row"
			} else if (column === "column-4") {
				columnName = "Front Row"
			}
			ui.push(<p>{columnName}: {playerList[team][column]}</p>)
			downloadString += columnName + ': ' + playerList[team][column] + '\n';
			if (teamData[team] === undefined) {
				teamData[team] = columnName + ': ' + playerList[team][column] + '\n';
			} else {
				teamData[team] = teamData[team] + columnName + ': ' + playerList[team][column] + '\n';
			}
			console.log(teamData)
		}
		ui.push(<p>Comments: {dragData[team]["comments"] ? dragData[team]["comments"] : 'No comment added.'}</p>)
		ui.push(<hr />);
		downloadString += '\n';
	}
	return ui;
})

const downloadSelectTeams = () => {
	const element = document.createElement("a");
	downloadString = '';
	// eslint-disable-next-line
	downloadString = 'TeamLayout Player Order Export generated on: ' + moment().format('YYYY-MM-DD') + ' at ' + moment().format('HH:MM:SS') + '\n' + '---------------------------------------------------------------------------------------------------------' + '\n\n';
	if (teamsToDownload.length === 0) {
		downloadString += '0 teams were selected during this export. Please select some teams using the checkboxes to see the export here.'
	}
	teamsToDownload.forEach((teamtd) => {
		console.log('teamtd', teamtd)
		console.log(teamData[teamtd])
		downloadString += teamtd + '\n';
		downloadString += `${teamData[teamtd]}\n`;
		downloadString += `Comments: ${dragData[teamtd]["comments"] ? dragData[teamtd]["comments"] : 'No comment added.'}\n`;
		downloadString += '\n---------------------------------------------------------------------------------------------------------\n\n';
		downloadString.replace('undefined', '')
	})
	const file = new Blob([downloadString], { type: 'text/plain' });
	element.href = URL.createObjectURL(file);
	element.download = downloadTitle;
	document.body.appendChild(element); // Required for this to work in FireFox
	element.click();
};

const downloadAllTeams = () => {
	const element = document.createElement("a");
	// eslint-disable-next-line
	downloadString = 'TeamLayout Player Order Export generated on: ' + moment().format('YYYY-MM-DD') + ' at ' + moment().format('HH:MM:SS') + '\n' + '---------------------------------------------------------------------------------------------------------' + '\n\n';
	
	for (const team in dragData) {
		// TODO: #39 @homemadestea58
		if (playerList[team] === undefined) {
			downloadString += '';
		} else {
			downloadString += team + '\n';
			for (const column in playerList[team]) {
				var columnName = "";
				console.log('Team Players')
				console.log(playerList[team][column])
				if (column === "column-1") {
					columnName = "Absent"
				} else if (column === "column-2") {
					columnName = "Back Row"
				} else if (column === "column-3") {
					columnName = "Middle Row"
				} else if (column === "column-4") {
					columnName = "Front Row"
				}
				downloadString += columnName + ': ' + playerList[team][column] + '\n';
			}
			downloadString += `Comments: ${dragData[team]["comments"] ? dragData[team]["comments"] : 'No comment added.'}`;
			downloadString += '\n---------------------------------------------------------------------------------------------------------\n\n';
		}
	}

	const file = new Blob([downloadString], { type: 'text/plain' });
	element.href = URL.createObjectURL(file);
	element.download = downloadTitle;
	document.body.appendChild(element); // Required for this to work in FireFox
	element.click();
};


function ExportPlayers(props) {
	let { id } = useParams();
	document.title = `Export teams`
	console.log(props)
	const [userReader] = useAsyncResource(fetchUser, id);
	return (
		<div>
			<div className="Players-header">
				<h1 style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>Players Export</h1>
				{/* eslint-disable-next-line*/}
				<a href="#" onClick={() => props.history.push('/')} style={{ display: 'flex', justifyContent: 'center', color: 'white', textDecoration: 'none' }}>Back to Home</a>
				<br></br>
				<div className='Export-content'>
					<Suspense fallback={
						<Loader
							type="TailSpin"
							color="#1e4475"
							height={100}
							width={100}
							timeout={3000} //3 secs
						/>
					}>
						{/*eslint-disable-next-line*/}
						<a href="#" style={{ display: 'flex', justifyContent: 'center', color: 'white', textDecoration: 'none' }} onClick={downloadAllTeams}>Download as TXT</a>
						{/*eslint-disable-next-line*/}
						<a href="#" style={{ display: 'flex', justifyContent: 'center', color: 'white', textDecoration: 'none' }} onClick={downloadSelectTeams}>Download selected teams as TXT</a>
						<br />
						<div>
							<User userReader={userReader} /* 👈 pass it to a suspendable child component */ />
						</div>
					</Suspense>
				</div>
			</div>
		</div>
	);
}
function User({ userReader }) {
	const userData = userReader(); // 😎 just call the data reader function to get the user object
	return userData;
}



export default withRouter(ExportPlayers);