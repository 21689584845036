import React from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-csv-importer/dist/index.css";
import firebase from '../firebase';

const db = firebase.firestore();

// if ((window.location.hostname === "localhost") || (window.location.hostname === "127.0.0.1")) {
//   db.settings({
//     host: "localhost:8081",
//     ssl: false,
//   })
// }

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

function htmlEncode(s) {
    var el = document.createElement('div');
    el.innerText = el.textContent = s;
    s = el.innerHTML;
    return s;
}

const addPlayerToDB = (player) => {
  return new Promise((resolve, reject) => {
    db.collection(`${firebase.auth().currentUser.uid}_teams`).doc(player.current_team_name).set({
      name: player.current_team_name
    }).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    })
  })
}

const addPlayersToDB = (playersInit, resolve) => {
    let players = playersInit.map((player) => {
        return {
            ...player,
            first_name: htmlEncode(player.first_name),
            surname: htmlEncode(player.surname),
            current_team_name: htmlEncode(player.current_team_name),
        }
    })
    let playerAddRequests = players.map(player => addPlayerToDB(player));


    var dragData = {};
    var playersl = {};
    for (var i=0; i < players.length; i++) {
  
      players[i].id = makeid(10);
  
      var newPlayer = {};
      newPlayer.id = players[i].id;
      newPlayer.content = players[i].fullname;
      // Code that allows for first name and surname to be uploaded separately
      // newPlayer.content = players[i].first_name + " " + players[i].surname;
      newPlayer.team = players[i].current_team_name;
  
      if (playersl[players[i].current_team_name] === undefined){
        playersl[players[i].current_team_name] = {};
      }
  
      playersl[players[i].current_team_name][players[i].id] = newPlayer;
      
      if (dragData[players[i].current_team_name] === undefined) {
        dragData[players[i].current_team_name] = {};
        dragData[players[i].current_team_name]['columns'] = {
          'column-1': {
            id: 'column-1',
            title: 'All Players',
            taskIds: [] 
          },
          'column-2': {
          id: 'column-2',
          title: 'Back Row',
          taskIds: [],
          },
          'column-3': {
          id: 'column-3',
          title: 'Middle Row',
          taskIds: [],
          },
          'column-4': {
          id: 'column-4',
          title: 'Front Row',
          taskIds: [],
          },
        };
      }
      dragData[players[i].current_team_name]['columns']['column-1']['taskIds'].push(playersl[players[i].current_team_name][players[i].id].id);
  
      dragData[players[i].current_team_name]['tasks'] = playersl[players[i].current_team_name];
      dragData[players[i].current_team_name]['columnOrder'] = ['column-1', 'column-2', 'column-3', 'column-4']
    }
    console.log(dragData)
    document.getElementById("importing-teams-message").innerHTML = "Uploading players to database...";
    Promise.all(playerAddRequests).then(() => {
      db.collection(`${firebase.auth().currentUser.uid}_teams`).get().then((querySnapshot) => {   
        querySnapshot.forEach((doc) => {
          console.log(doc.id, doc.id in dragData)
          if (!(doc.id in dragData)) {
            console.log(`Skipping team: ${doc.id}`)
          } else {
            console.log('Adding.....')
            db.collection(`${firebase.auth().currentUser.uid}_teams`).doc(doc.id).set({
              name: doc.id,
              imageUrl: '',
              dragndropdata: dragData[doc.id]
            }).then(() => {
              document.getElementById("importing-teams-message").style.display = "none";
              var addedTeamsElement = document.getElementById("teams-add")
              var teamAdded = document.createElement("li");
              teamAdded.innerText = `${doc.id}`
              addedTeamsElement.appendChild(teamAdded)
            })
          }

      })
      })
    }).then(() => {
      resolve();
    })
}

const NewImporter = ({club, closeModal}) => {
  //@ts-ignore
  //eslint-disable-next-line
  let fieldsToUse;
    return (
        <Importer
        chunkSize={10000} // optional, internal parsing chunk size in bytes
        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
          restartable={false} // optional, lets user choose to upload another file when import is complete
          onStart={({ file, fields }) => {
              // optional, invoked when user has mapped columns and started import
              console.log("starting import of file", file, "with fields", fields);
              fieldsToUse = fields
            }}
          processChunk={async (rows) => {
                // required, receives a list of parsed objects based on defined fields and user column mapping;
            // may be called several times if file is large
            // (if this callback returns a promise, the widget will wait for it before parsing more data)
            console.log(rows)
            // mock timeout to simulate processing
            // await new Promise((resolve) => logPlayers(rows));
            await new Promise((resolve) => {addPlayersToDB(rows, resolve)})
            toast.success(`All done! ${rows.length} players imported!`);
          }}
          onComplete={({ file, fields }) => {
            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
            console.log("finished import of file", file, "with fields", fields);
          }}
          onClose={() => {
            // optional, invoked when import is done and user clicked "Finish"
            // (if this is not specified, the widget lets the user upload another file)
            console.log("importer dismissed");
            closeModal();
            window.location.reload();
          }}
        >
          {/* <ImporterField name="first_name" label="First Name" />
          <ImporterField name="surname" label="Last Name" /> */}
          <ImporterField name="fullname" label="Full Name" />
          <ImporterField
            name="current_team_name"
            label="Age Group / Team"
          />
        </Importer>
  );
  
}

// NewImporter.propTypes = {
//     club: PropTypes.number.isRequired,
//     rows: PropTypes.array.isRequired,
//     resolve: PropTypes.func.isRequired,
//     closeModal: PropTypes.func.isRequired,
// }

export default NewImporter;
