import React from 'react';
import './App.css';
import LoginForm from './components/LoginForm';

function LoginScreen() {
  document.title = `Login to TeamLayout`
  return (
    <div>
      
      <div className="App">
        <header className="App-header">
          <LoginForm></LoginForm>
        </header>
      </div>
    </div>
  );
}

export default LoginScreen;
