import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics';

// if (window.location.hostname === ('teamlayout-staging.netlify.app' || 'localhost' || '127.0.0.1')) {
//   firebase.initializeApp({
//     apiKey: "AIzaSyBzkcJAo6-L8SXY71_SjDOZSQvjWmpl4Ks",
//     authDomain: "teamlayout-development.firebaseapp.com",
//     projectId: "teamlayout-development",
//     storageBucket: "teamlayout-development.appspot.com",
//     messagingSenderId: "406285914516",
//     appId: "1:406285914516:web:88ed9e72b4c5b73609bf81",
//     measurementId: "G-2HPR68XEWE"
//   })
// } else {
  firebase.initializeApp({
    apiKey: "AIzaSyD0D8MMfRS-6PoDAdmDyyjGD0JifUOckQw",
    authDomain: "hhfc-photo-name-order.firebaseapp.com",
    projectId: "hhfc-photo-name-order",
    storageBucket: "hhfc-photo-name-order.appspot.com",
    messagingSenderId: "687645098158",
    appId: "1:687645098158:web:e60e66806f2e6586576d3f",
    measurementId: "G-6Q47M14CYG"
  }); 
// }

const analytics = firebase.analytics();
export { analytics };
export default firebase;


