import React from 'react';
import '../App.css';
import firebase from '../firebase';
import HomeScreen from '../HomeScreen';
import logo from '../teamlayout-logo.svg';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import moment from "moment";


function LoginForm() {
  class SignInScreen extends React.Component {
    
    constructor(props) {
      super(props)
      this.state = {
        isSignedIn: false, // Local signed-in state.
        email: '',
        password: '',
    };
      
      this.handleChangeEmail = this.handleChangeEmail.bind(this);
      this.handleChangePassword = this.handleChangePassword.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    // The component's Local state.


    // Configure FirebaseUI.
    uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // We will display Google and Email as auth providers.
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    };

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
      this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
          (user) => this.setState({isSignedIn: !!user})
      );
      if (this.state.isSignedIn) {
        document.getElementById('homeScreen').style.display = 'block';
        document.getElementById('loader').style.display = 'none';
       } else {
        document.getElementById('loginForm').style.display = 'block';
        document.getElementById('loader').style.display = 'none';
      }
    }
    
    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
      this.unregisterAuthObserver();
    }

    handleChangeEmail(event) {
      this.setState({email: event.target.value});
    }
    handleChangePassword(event) {
      this.setState({password: event.target.value});
    }
  
    async handleSubmit(event) {
      document.getElementById('loader').style.display = 'block';
      document.getElementById('loginForm').style.display = 'none';
      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((userCredential) => {
              fetch('/.netlify/functions/get-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    email: this.state.email
                })            
              }).then(res => res.json()).then((subscriptions) => {
                console.log(subscriptions);
                if (subscriptions.error) {
                  console.log(subscriptions.error);
                } else {
                  if (subscriptions.validSubscriptions.length > 0) {
                    let endDate = moment.unix(subscriptions.endDateEpoch);
                    this.setState({isSignedIn: true});
                    localStorage.setItem('userId', JSON.stringify(userCredential.user.uid));
                    sessionStorage.setItem('billingEndDate', endDate.format("D/M/YYYY"))
                    window.location.reload();
                    document.getElementById('homeScreen').style.display = 'block';
                    document.getElementById('loader').style.display = 'none';
                  } else {
                    alert('Error: Invalid subscription.\nPlease ensure you have a valid subscription to TeamLayout.');
                    firebase.auth().signOut();
                    window.location.reload();
                  }
                }
        })
      })
        .catch((error) => {
          var errorCode = error.code;
          console.log(error.code)
          if (errorCode === 'auth/invalid-email'){
            alert('Email was invalid or badly formatted')
            document.getElementById('loader').style.display = 'none';
          document.getElementById('loginForm').style.display = 'block';
        } else if (errorCode === 'auth/wrong-password') {
          alert('Password was invalid');
          document.getElementById('loader').style.display = 'none';
          document.getElementById('loginForm').style.display = 'block';
        } else if (errorCode === 'auth/user-not-found') {
          alert('User not found');
          document.getElementById('loader').style.display = 'none';
          document.getElementById('loginForm').style.display = 'block';
        };
      });
      event.preventDefault();
    }

    render() {
      if (!this.state.isSignedIn) {
        return (
          <>
            <div id="loader" style={{display: 'block'}}>
            <Loader
              type="TailSpin"
              color="#1e4475"
              height={100}
              width={100}
              // timeout={3000} //3 secs
            />
          </div>
          <div id="loginForm" style={{display: 'none'}}>
            <img src={logo} className="App-logo" alt="logo" />
            <h1 style={{color: 'white'}}>TeamLayout</h1>
            <p>Please sign-in:</p>
            {/* <div style={{'display': 'none'}}><StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/></div> */}
            {this.state.loading ? 
              <p>Loading.......</p>
            : 
            <>
            <form onSubmit={this.handleSubmit}>
            <label>
              Email:
              <input style={{'backgroundColor': 'white'}} type="email" value={this.state.email} onChange={this.handleChangeEmail} />
            </label>
            <br />
            <label>
              Password:
              <input style={{'backgroundColor': 'white'}} type="password" value={this.state.password} onChange={this.handleChangePassword} />
            </label>
            <br />
            <input type="submit" value="Submit" />
          </form>
          <button onClick={() => window.location.replace('https://buy.stripe.com/test_cN29E581Ackcgfu5kp')}>Sign Up</button>
          <p>{this.state.loadingText}</p>
          </>
          }
          </div>
          </>
        );
      }
      return (
        // <HomeScreen />
        <>
          <div id="loader2" style={{display: 'block'}}>
            <Loader
              type="TailSpin"
              color="#1e4475"
              height={100}
              width={100}
              // timeout={3000} //3 secs
            />
            </div>
          <div id="homeScreen" style={{display: 'none'}}>
            <HomeScreen/>
          </div>
        </>
      );
    }
  }
  return (<SignInScreen />);
}

export default LoginForm;
