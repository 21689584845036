import React from 'react';
import './Button.css';
// import { withRouter } from 'react-router';

/**
 * Button
 * @param {backgroundColor} backgroundColor - background color of the button
 * @param {label} label - Button label
 * @param {onClick} onClick - onClick function
 * @param {textColor} textColor - text color of the button
 * @param {fontWeight} fontWeight - font weight of the button
 * @param {bold} bold - bold button
 * @returns {React.Component} - Button component
 */
const Button = ({ backgroundColor, label, onClick, textColor, fontWeight, bold}) => {
  return (
    <div style={{backgroundColor: backgroundColor ? backgroundColor : 'white'}} className={"teamBox"} key={label}>
        {/* <span><img alt={'teamIcon'} src={HHFCLogoNoGreen} style={{width: 25, height: 'auto', verticalAlign: 'baseline'}}/></span> */}
        {/* eslint-disable-next-line*/}
        {/* <span className={"teamLink"} ><a style={{color: backgroundColor, textDecoration: 'none'}} href='#' onClick={() => window.location.replace("/team/edit/"+team)}>{team}</a></span> */}
        {/* eslint-disable-next-line*/}
        <span style={{color: textColor ? textColor : 'black', fontWeight: fontWeight ? fontWeight : bold ? 'bold' : ''}} className={"teamLink"} onClick={onClick ? onClick : () => {}}>{label}</span>
    </div>
  );
};

export default Button;
