import React, { useRef } from "react";
import firebase from '../../firebase';
import Button from "../Button/Button";
const firestore = firebase.firestore();

const UploadButtonComponent = ({team}) => {
    const ref = useRef(null);
  
    const handleClick = () => {
      if (ref) {
        return ref.current?.click();
      }
    };

  
    const handleUpload = async (event) => {
      if (!firebase) return;
  
      const uploadedFile = event?.target.files[0];
      if (!uploadedFile) return;
      const storage = firebase.storage();
      const storageRef = storage.ref("images");
      var loader = document.getElementById("photoLoader");
      loader.style.display = 'block';
      try {
        await storageRef
          .child(uploadedFile.name)
          .put(uploadedFile)
          .then((snapshot) => {
            console.log("Download url");
            console.log("Snapshot");
            console.log(snapshot);
          });
        storageRef
          .child(uploadedFile.name)
          .getDownloadURL()
          .then((url) => {
            console.log(url);
            var img = document.getElementById("myimg");
            img.src = url;
            firestore.doc(`/${firebase.auth().currentUser.uid}_teams/${team}`).update({
                imageUrl: url
            });
          });
          loader.style.display = 'none';
        alert("Successfully uploaded picture!");
      } catch (error) {
        console.log("error", error);
      }
    };
  
    return (
      <div>
        <br />
        <Button textColor="#320FFA" label="Upload Photo" onClick={() => handleClick()} />
        <input
          type="file"
          ref={ref}
          accept=".png, .jpg, .jpeg"
          hidden
          onChange={handleUpload}
        />
      </div>
    );
  };

export default UploadButtonComponent;