import '@atlaskit/css-reset';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled from 'styled-components';
import firebase from '../firebase';
import Column from './column-h';
import './DND.css';
import { withRouter } from 'react-router';
import Button from '../components/Button/Button';


var db = firebase.firestore();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // min-width: 100%;
  width: 98%;
  max-width: 100%;
  margin-right: 8px;
  flex-wrap: wrap;
`;

function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

class DNDD extends React.Component {
  state = null;
  state = this.props.dragData

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }


    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      const newTaskIds = Array.from(home.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };
      this.setState(newState);
      return;
    }

    // moving from one list to another
    const homeTaskIds = Array.from(home.taskIds);
    homeTaskIds.splice(source.index, 1);
    const newHome = {
      ...home,
      taskIds: homeTaskIds,
    };

    const foreignTaskIds = Array.from(foreign.taskIds);
    foreignTaskIds.splice(destination.index, 0, draggableId);
    const newForeign = {
      ...foreign,
      taskIds: foreignTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    this.setState(newState);
  };

  addPlayer() {
    document.getElementById('addNew').value = '';
    var playerId = makeid(10);
    console.log('Player ID', playerId)
    var column1 = this.state.columns['column-1'].taskIds;
    console.log(column1)
    column1.push(playerId);
    console.log(column1)
    this.setState({
      ...this.state,
      tasks: {
        ...this.state.tasks,
        [playerId]: {
          id: playerId,
          content: this.state.addPlayer,
          team: this.props.teamID,
        }
      },
      columns: {
        ...this.state.columns,
        'column-1': {
          ...this.state.columns['column-1'],
          taskIds: column1
        }
      }
    })
  }

  
  saveState() {
    var img = document.getElementById("myimg");
    console.log('Image Component')
		console.log(img)
    document.getElementById('dndui').style.display = 'none';
    document.getElementById('saveSpinner').style.display = 'flex';
    console.log('State')
    console.log(this.state)
    try {
      db.collection(`${firebase.auth().currentUser.uid}_teams`).doc(this.props.teamID).update({
        name: this.props.teamID,
        dragndropdata: this.state,
        isEdited: true,
        comments: document.getElementById('comments').value
      }).then(() => {
        this.forceUpdate();
        window.location.replace('/');
        // this.props.history.push('/');
      })
  } catch(error) {
    console.log(error)
  }

  }
  render() {
    // console.log('DND Copy ref', this.props.commentsBox)
    // console.log(document.getElementById('comments').value)
    document.title = `Editing team: ${this.props.teamID}`
    if (this.state === null) {
      var img = document.getElementById("teamImage");
      if (img) {
				img.style.display = 'none';
			}
      return (
        <div>
          <h1 style={{color: 'white', 'display':'flex', 'justifyContent': 'center'}}>No players were registered for this team</h1>
        </div>
      )
    } else {
      return (
        <>
        <div style={{'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'marginLeft': '8px'}}>
          <p style={{'fontSize': '20px'}}>Add new Player/Coach/Manager:</p><br/>
          <input style={{'height': '15px', 'backgroundColor': 'white'}} type="text" id="addNew" placeholder="Name:" onChange={(e) => {
            this.setState({
              addPlayer: e.target.value
            })
          }}></input>
          <button onClick={() => this.addPlayer()}>Add</button>
        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Container>
            {this.state.columnOrder.map(columnId => {
              const column = this.state.columns[columnId];
              const tasks = column.taskIds.map(
                taskId => this.state.tasks[taskId],
              );

              return <Column key={column.id} column={column} tasks={tasks} />;
            })}
            <div style={{'marginLeft': 'auto', 'marginRight': '10px'}}>
              {/* eslint-disable-next-line*/}
              <Button onClick={()=>this.saveState()} label="Save" textColor="red" fontWeight="bold"></Button>
            </div>
            
            
            
          </Container>
        </DragDropContext>
        </>
      );
    }
  }
}

export default withRouter(DNDD);