import '@atlaskit/css-reset';
import React, { Suspense } from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useAsyncResource } from 'use-async-resource';
import './App.css';
import DNDD from './dnd dynamic/dnd copy';
import firebase, {analytics} from './firebase';
import UploadButtonComponent from './components/TeamImage/TeamImage.component';
import { withRouter } from 'react-router';

var userId = '';
if ((localStorage.getItem('userId') !== null) && (window.location !== '/team/edit')) {
	userId = localStorage.getItem('userId').replaceAll('"', '')
	console.log('User id', userId)
} else {
	// window.location.replace('/');
}

console.log(userId)
var club = '';
if (localStorage.getItem('club') !== null) {
	club = localStorage.getItem('club').split('"').join('');
	console.log(club)	
}/* else {
	window.location.replace('/')
}*/

const db = firebase.firestore();

if ((window.location.hostname === "localhost") || (window.location.hostname === "127.0.0.1")) {
  db.settings({
    host: "localhost:8081",
    ssl: false,
  })
}

let ui = [];

const fetchUser = (props) => db.collection(`${userId}_teams`).doc(props.id).get().then((doc) => {
	ui = (<DNDD userId={userId} teamID={props.id} dragData={doc.data().dragndropdata}></DNDD>)
	return ui;
})


function PlayersScreen({history, match: {params: {id}}}) {

	const commentsBox = React.useRef(null);


	document.title = 'Loading......'
	console.log(id)
	console.log(`/${userId}_teams/${id}`)
	db.doc(`/${userId}_teams/${id}`).get().then((doc) => {
		console.log(userId)
		console.log(doc.data())
		var img = document.getElementById("myimg");
		// console.log(img)
		// if(doc.data().imageUrl === undefined) {
		if (img) {
			img.setAttribute("src", doc.data().imageUrl);
			console.log('Set image')
		}
		// }
		var commentsBox = document.getElementById("comments");
		if (commentsBox) {
			if (!doc.data().comments) {
				commentsBox.value = '';
			} else {
				commentsBox.value = doc.data().comments;
				console.log('Set image')
			}
		}
    })
	analytics.logEvent('edit_team', {
		content_type: 'team',
		content_id: 'team_edit'
	})
	const [userReader] = useAsyncResource(fetchUser, {id});
	return (
		<>
			<div id='Players-header' className="Players-header">
				<h1 style={{color: 'white', 'display':'flex', 'justifyContent': 'center', fontSize: '30px'}}>Team: {id}</h1>
				{/* eslint-disable-next-line*/}
				<a href="#" onClick={() => history.push('/')} style={{ textDecoration: 'none', color: 'white', 'display': 'flex', 'justifyContent': 'center', fontSize: '20px'}}>Back to home</a>
				<div id='saveSpinner' style={{display: 'none', 'justifyContent': 'center'}}>
					<br></br>
					<Loader type="TailSpin" color="#1e4475" height={100} width={100} />	
				</div>
				<br></br>
				<div id="dndui" className='Players-content' style={{display: 'block'}}>
					<Suspense fallback=	{<div style={{'display':'flex', 'justifyContent': 'center'}}><Loader
											type="TailSpin"
											color="#1e4475"
											height={100}
											width={100}
											timeout={3000} //3 secs
										/></div>}>
						<div style={{'height': 'auto', 'position': 'relative', 'left': 0}}>
							<User userId={userId} userReader={userReader} /* 👈 pass it to a suspendable child component */ />
							<div style={{'display':'flex', 'flexDirection':'row', alignItems: 'baseline', width: '95vw'}}>
								<p style={{marginLeft: '8px', marginRight: '10px', fontSize: '17px'}}>Comments:  </p>
								<textarea ref={commentsBox} type="text" id="comments" placeholder="Comments" style={{width: '100%'}} rows="3"></textarea>
							</div>
							<div id='teamImage'>
								<div style={{'marginLeft': '8px'}}>
									<UploadButtonComponent team={id}></UploadButtonComponent>
								</div>
								<div style={{'display':'flex', 'flexDirection':'row', 'justifyContent': 'center',}}>
									<div id="photoLoader" style={{"display": "none"}}>
										<Loader type="TailSpin" color="#1e4475" height={100} width={100} />
									</div>
									{/*eslint-disable-next-line*/}
									<img className="teamImage" src="" id="myimg"></img>
								</div>
							</div>
						</div>
					</Suspense>
				</div>
			</div>

		</>
	);
}
function User({ userReader }) {
	const userData = userReader(); // 😎 just call the data reader function to get the user object
	return userData;
}

export default withRouter(PlayersScreen);