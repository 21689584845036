import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect
} from "react-router-dom";
import LoginScreen from './LoginScreen';
import PlayersScreen from './PlayersScreen copy 2';
import HomeScreen from './HomeScreen';
import ExportPlayers from './ExportPlayers';
import Logout from './components/Logout';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

var usr = firebase.auth().currentUser;
console.log('User object')
console.log(usr);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: firebase.auth().currentUser,
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ user });
    });
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Email as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginScreen />
          </Route>
          <Route path="/home">
            <HomeScreen></HomeScreen>
          </Route>
          <Route path="/team/edit/:id" component={(props) => this.state.user ? <PlayersScreen props={props}/> : <Redirect to="/" />}></Route>
          <Route path="/export" component={(props) => this.state.user ? <ExportPlayers props={props}/> : <Redirect to="/" />}>
          </Route>
          <Route path="/logout" component={(props) => this.state.user ? <Logout props={props}/> : <Redirect to="/" />}>
          </Route>
          <Route path="/teamlayout2021_signup_here"> <div style={{'display': 'none'}}><StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/></div>
          </Route>
        </Switch>
      
      </Router>
    );
  }
}



export default App;
